/* eslint-disable react/no-danger */
import React, { FC, useEffect } from 'react';
import {
  Box,
} from '@components/Primitives';
import { observer } from 'mobx-react';
import styled from 'styled-components';
import { colors } from '../styles/theme';
import { Layout } from '@components/Layout';

// @ts-ignore
import Logo from '@icons/logo_mobile.svg';
import { until } from '../styles';
import { FaqAccordion } from '@components/Accordion';
import { useStores } from '../stores/index';
import { PATHS } from '../constants/nav-items';
import { FormattedMessage as M, useIntl } from 'gatsby-plugin-intl';
import { Link } from '@primitives';
interface ILoginFaqPage {
  newsStore: INewsStore;
  location: any;
}

const LogoBar = styled.div`
  background-color: ${colors.lightBlue};
  height: 150px;
  display: flex;
  justify-content: center;
  align-items: center;
  ${until('md', `
    height: 80px;
  `)};
`;

const Content = styled.div`
  flex: 2 1 100%;
  overflow-y: auto;
  padding: 1rem;
  max-height: calc(100vh - 300px);
  ${until('md', `
    max-height: calc(100vh - 160px);
  `)};
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction:column;
  height: 100vh;

`

const LoginFaqPage: FC<ILoginFaqPage> = observer(() => {
  const {
    faqStore: { getFaqList, faqLists }
  } = useStores();

  const intl = useIntl();
  const currentLanguage = intl.locale;

  useEffect(() => {
    if (currentLanguage) {
      getFaqList('login', currentLanguage);
    }
  }, []);

  const faqList = faqLists.find((list: IFaqList) => list.slug === 'login');
  return (
    <Layout customLayout>
      <Wrapper>
        <LogoBar>
          <Logo />
        </LogoBar>
        <Content>
          <Box centerContent mb="2">
            <Link to={PATHS.auth.login} inline><M id="pages.loginFaq.backToLogin" /></Link>
          </Box>
          <Box>
            {faqList?.faqs && (
              <FaqAccordion id="login-faq" items={faqList.faqs} />
            )}
          </Box>
        </Content>
        <LogoBar>
          <Logo />
        </LogoBar>
      </Wrapper>
    </Layout>
  );
});

export default LoginFaqPage;
